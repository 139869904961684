import { useState, useEffect, useMemo } from "react";
import { getApi, putApi } from "../../utils/api";
import { HIRING_URL } from "../../helpers/constants";
import { RouteConstants } from "../../helpers/RouteConstants";
import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import moment from "moment";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { HiringData } from "../hirings/payload.model";
import { Typography } from "@material-tailwind/react";
import StatusSelector from "../../components/general/statusSelector";
import { Box, ListItemIcon, MenuItem } from "@mui/material";
import { Approval } from "@mui/icons-material";
import { notification } from "antd";

const VehiclesPage = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [allHiringRequests, setAllHiringRequets] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
  });
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [reFetch] = useState(false);

  const handleStatusChange = (value: string) => {
    setSelectedStatus(value);
  };

  const fetchVehicles = async () => {
    setIsFetching(true);
    try {
      let offset = pagination.pageIndex * pagination.pageSize;

      const defaultFilters = [];

      if (selectedStatus) {
        defaultFilters.push({
          f: "status.code",
          o: "=",
          p: [selectedStatus],
        });
      }

      const response = await getApi(
        `${HIRING_URL}${
          RouteConstants.VEHICLES
        }?sorting:created_at:desc&limit=${
          pagination.pageSize
        }&offset=${offset}&filters=${JSON.stringify(defaultFilters)}`
      );
      // console.log(response?.data?.payload);
      setAllHiringRequets(response?.data?.payload?.items);
      setTotal(response?.data?.payload?.total);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
    }
  };

  //on page load
  useEffect(() => {
    fetchVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex, pagination.pageSize, selectedStatus, reFetch]);

  const approveVehicle = async (vehicleId: string) => {
    setIsFetching(true);
    try {
      await putApi(
        `${HIRING_URL}${RouteConstants.VEHICLES}/${vehicleId}/mark-as-approved`,
        {}
      );
      await fetchVehicles();
      setIsFetching(false);
      notification.success({ message: `Vehicle has been approved` });
    } catch (error: any) {
      notification.error({
        message:
          error.response?.data?.message ||
          error.message ||
          "Failed to approve vehicle",
      });

      console.log(error);
    }
  };

  // table columns
  const columns = useMemo<MRT_ColumnDef<HiringData>[]>(
    () => [
      {
        accessorFn: (row: HiringData) => row?.brand?.name,
        id: "brand",
        header: "Brand",
      },
      {
        accessorFn: (row: HiringData) => row?.model?.name,
        id: "model",
        header: "Model",
      },
      {
        accessorFn: (row: HiringData) => row?.passenger_capacity,
        id: "passenger_capacity",
        header: "Passenger Capacity",
      },
      {
        accessorFn: (row: HiringData) => `${row?.staff_capacity}`,
        id: "staff_capacity",
        header: "Staff Capacity",
      },
      {
        accessorFn: (row) => row?.approved,
        Cell: ({ cell }: { cell: any; row: any }) => (
          <span>{cell?.getValue() ? "Yes" : "No"}</span>
        ),
        id: "approved",
        header: "Approved",
      },
      {
        accessorFn: (row) => row?.available,
        Cell: ({ cell }: { cell: any; row: any }) => (
          <span>{cell?.getValue() ? "Yes" : "No"}</span>
        ),
        id: "available",
        header: "Available",
      },
      {
        accessorFn: (row: HiringData) => `${row?.created_at}`,
        id: "created_at",
        header: "Date Created",
        Cell: ({ cell }: any) => (
          <Box>{moment(cell?.getValue()).format("LLL")}</Box>
        ),
        size: 160,
      },
      {
        accessorFn: (row: HiringData) => `${row?.updated_at}`,
        id: "updated_at",
        header: "Date Updated",
        Cell: ({ cell }: any) => (
          <Box>{moment(cell?.getValue()).format("LLL")}</Box>
        ),
        size: 160,
      },

      // {
      //   id: "more",
      //   header: "Action",
      //   accessorFn: (row) => (
      //     <Box>
      //       <Dropdown
      //         trigger={["click"]}
      //         menu={{
      //           items,
      //         }}
      //         placement="bottomRight"
      //       >
      //         <button
      //           onClick={() => {
      //             setSavedData(row);
      //           }}
      //         >
      //           <Space>
      //             More
      //             <ArrowDropDownOutlined />
      //           </Space>
      //         </button>
      //       </Dropdown>
      //     </Box>
      //   ),
      //   size: 5,
      // },
    ],

    // eslint-disable-next-line
    []
  );

  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="flex h-full overflow-hidden">
          {/* sidebar */}
          <CustomSideBar vehiclesActive="active" />
          {/* navbar */}
          <div className="w-full h-full">
            <CustomNavbar />

            {/* page content */}
            <div className="p-[10px] lg:p-[20px] h-full overflow-y-auto bg-[#7c000008]">
              <div>
                <Typography className="font-semibold text-xl">
                  Vehicles
                </Typography>
              </div>
              <div className="py-3 px-5 mt-3 rounded-[10px] bg-white shadow-md w-full flex justify-between items-center">
                <div className="w-[250px]">
                  <small>Filter by status</small>
                  <StatusSelector
                    onChange={(val) => handleStatusChange(val)}
                    allowClear={true}
                  />
                </div>
                <div>{/* Right Actions */}</div>
              </div>

              <div className="mt-4 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={allHiringRequests}
                  enableRowSelection={false}
                  rowCount={total}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{ pagination, isLoading: isFetching }}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                    columnPinning: {
                      left: ["mrt-row-expand", "mrt-row-select"],
                      right: ["mrt-row-actions"],
                    },
                  }}
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 120,
                  }}
                  enableRowActions={true}
                  renderRowActionMenuItems={({ closeMenu, row }) => [
                    <MenuItem
                      key={1}
                      onClick={async () => {
                        await approveVehicle(row.original.id);
                        closeMenu();
                      }}
                      sx={{ m: 0 }}
                    >
                      <ListItemIcon>
                        <Approval />
                      </ListItemIcon>
                      Approve
                    </MenuItem>,
                  ]}
                  renderDetailPanel={({ row }) => (
                    <>
                      {row.original.image_url && (
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            alt="avatar"
                            src={row.original.image_url}
                            loading="lazy"
                            style={{ height: "200px" }}
                          />
                        </Box>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VehiclesPage;
